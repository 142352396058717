

/* body::-webkit-scrollbar {
    width: 0;
    -webkit-appearance: none;
} */

 body::-webkit-scrollbar {
    width: 0px;
   
}

/* snackbar is not visible under pop ups without that */
.SnackbarContainer-root  {
    z-index: 2001!important;
}